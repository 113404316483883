import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { HelperService } from 'src/app/api/helper.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/utils/auth.service';
import { ApiService } from '../../../api/api.service';
import { colorConfig } from 'src/environments/color-configs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  role='';
  showAdminHeaders = true;
  appVersion:number;
  orgDetails: any;
  pilotMode: number;
  orgId='';
  authResult:any;
  pageLoaded = false;
  isSmartpath: boolean = false;
  domain = '';
  logo='';
  showUser = environment.userPage;
  GIS: number;
  showAdminMenu=false;
  tokenValue: any = {};
  isMaster: boolean = false;

  constructor(private router: Router, private authService: AuthService,
    private apiService: ApiService, private helper : HelperService) { }

  ngOnInit() {
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.authResult = session;
        this.apiService.setAuthResult(session);
        this.tokenValue = {}
        this.isMaster = false;
        var storageRole = this.apiService.getLocalStorage('role');
        if(storageRole === 'Master' && !window.location.href.includes('Management-console') 
          && !window.location.href.includes('Dashboard')){
          this.isMaster = true;
          var masterTokens = JSON.parse(this.apiService.getLocalStorage('masterTokens'));
          var organizationId = window.location.href.split('=').pop()
          var currentOrgToken = masterTokens[Number(organizationId)].split(' ')
          this.tokenValue = jwt_decode<JwtPayload>(currentOrgToken[1])
          this.apiService.managementToken = currentOrgToken[1];
          this.apiService.useManagementToken = true;
        }else {
          this.apiService.managementToken = session.getIdToken().getJwtToken();
          this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        }
        this.orgId = this.tokenValue['custom:organizationid'];
        this.getOrgDetails();
      }
    });
    
  }
  async getOrgDetails() {
    var url = 'organization?orgId=' + this.orgId;
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      this.handlePortalStyles();
      console.log(data);
      this.orgDetails = data;
      if(data){
        this.appVersion = data.organization['Lite']
        this.pilotMode = data.organization['Pilot'];
        this.GIS = data.organization['GIS'];
      }
      this.role = localStorage.getItem('role')
      this.isSmartpath=JSON.parse(this.apiService.getLocalStorage('isSmartpath'));
      this.getUsername();
      if(this.role && this.role.toLowerCase() !== 'admin'){this.showAdminHeaders = false;}
      this.pageLoaded = true;
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  goto(getPath) {
    // this.toggleSideBarForMe.emit();
    var qParam = {}
    if( this.role === 'Master'){
      qParam = { manageOrg: this.orgId }
    }
    this.router.navigate([getPath],{ queryParams: qParam});
  }
  async getUsername() {
    const name = this.apiService.getLocalStorage('username');
    if(name.includes("steven")){
      this.showAdminMenu=true;
    }
    // if (name && name != this.username) {
    //   this.username = name;
    //   console.log("name".toLowerCase);
    // }
    // return name;
  }
  gotoGridZone(type: string,getUrl: any){
    if(this.pilotMode !==1){
    this.router.navigate([getUrl], 
        { queryParams: { reportScreenType: type, manageOrg: this.orgId }});
    }
  }
  handlePortalStyles(){
  this.domain = this.apiService.domain;
  if(this.domain){
    this.logo = colorConfig[this.domain]['logoName'];
  }
  }

}
