import { ChangeDetectorRef, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDrawer } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ThemePalette } from '@angular/material';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { EditComponent } from 'src/app/shared/components/edit/edit.component';
import { FormControl } from '@angular/forms';
import { AgmInfoWindow, MapsAPILoader, MouseEvent } from '@agm/core';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { GeocodeService } from 'src/app/api/geocode.service';
import { colorConfig } from 'src/environments/color-configs';
import { DatePipe, DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-transport-public',
  templateUrl: './transport-public.component.html',
  styleUrls: ['./transport-public.component.scss']
})
export class TransportPublicComponent implements OnInit {
  showCurrentLocation = false;
  // @ViewChild('search',{ static: true }) 
  @ViewChild('search', { static: true }) searchElementRef: ElementRef;
  @ViewChild('geoJsonInfoWindow', { static: false }) geoJsonInfoWindow: AgmInfoWindow;
  @ViewChild('drawer', { static: true }) drawer: MatDrawer;
  // public searchElementRef: ElementRef;
  public id: string;
  backendVersion: any;
  domain = 'fleetpaths';
  alertClass = "showAlert"
  siteurl = '';
  showMap = false;
  lat: any;
  deviceWidth:any;
  showView = true;
  publicAlert='';
  formClass = 'left';
  leftBgColor = '#f1772e';
  myLocationBg = '';
  mapClass = 'map-container-70';
  lng: any;
  publicLat: any;
  publicLng: any;
  mapType = 'roadmap'; //roadmap or satellite
  satellite = false;
  zoom = 13;
  helpUrl = '';
  intervalId:any;
  BusIntervalId:any;
  truckIntervalId:any;
  CurrentLat: any;
  CurrentLong: any;
  public color: ThemePalette = 'primary';
  url = '';
  status: any;
  streetPriorities: any = [];
  selected = new FormControl(0);
  myLocation = '';
  allTabs = [];
  showPage = false;
  welcomeMessage = true;
  address = '';
  welcomeLogo = '../../../../assets/logos/SnowPaths-logo.png';

  // 983293 magic number
  orgId: any;
  portalId: any;
  idlist: any;
  publicmapSettings: any;
  authResult: any;
  selectedMarker: any;
  showWelcome = false;
  public customStyle = [{
    "featureType": "poi",
    "stylers": [{
      visibility: "off",
    }]
  },];
  // address='';
  private geoCoder;
  priorityMapType: string = '';
  publicMapType: string = '';
  selectedgJsonStreetData: any;
  clickedLatLng: any;
  publicRouteList: any = [];
  allTrType = [{
    Id: 0,
    RouteName: "All routes"
  }]
  routeGjson = {}
  busStopLists = []
  selectedRoute = {}
  selectedRouteId = 0;
  busReport: any
  showRoute: boolean = false;
  selectedBusStopDetails: any = {}
  showContent: boolean = true;
  allRouteStreetsData: any = []
  allBusDetails = []
  showPins: boolean = true;

  constructor(private activatedRoute: ActivatedRoute, private apiService: ApiService, private ref: ChangeDetectorRef,
    private helper: HelperService, private dialog: MatDialog, private geocodeService: GeocodeService, private http: HttpClient,
    private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, @Inject(DOCUMENT) private _document: HTMLDocument,
    private titleService: Title, private changeDetectorRef: ChangeDetectorRef, private datepipe: DatePipe) { }

  ngOnInit() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.BusIntervalId) {
      clearInterval(this.BusIntervalId);
    }
    
    this.deviceWidth = window.innerWidth;
    const urlOrigin = window.location.origin;
    this.url = this.activatedRoute['_routerState'].snapshot.url;
    this.siteurl = urlOrigin + this.url;
    console.log(this.siteurl);
    let isAvailable = this.siteurl.includes("streetpaths");
    let isFleetpathAvailable = urlOrigin.includes("fleetpaths");
    if (isAvailable) {
      // this.domain = 'streetpaths';
      // this.welcomeLogo = '../../../../assets/logos/StreetPathsLogo.png'
      this._document.getElementById('appFavicon').setAttribute('href', 'streetpaths-favicon.png');
      this.titleService.setTitle('- streetpaths-4.0');
    }
    if(isFleetpathAvailable){
      // this.domain='fleetpaths';
      this._document.getElementById('appFavicon').setAttribute('href', 'fleetpaths-favicon.png');
      this.titleService.setTitle('- fleetpaths-4.0'); 
    }
    var isPublic = this.url.includes('Public')? true: false;
    var isQueryExist = this.url.includes('?')?true:false;
    if(isQueryExist){
      // Find the index of '?'
      const indexOfQuestionMark = this.url.indexOf('?');

      // Split the URL
      const urlWithoutQuery = this.url.substring(0, indexOfQuestionMark); // Part before '?'
      this.id = urlWithoutQuery[0].includes('Transport')?urlWithoutQuery[0].split('/Transport/').pop():urlWithoutQuery.split('/transport/').pop();
      this.idlist = this.id.split('/');
    }else {
      this.id = this.url.includes('Transport')?this.url.split('/Transport/').pop():this.url.split('/transport/').pop();
      this.idlist = this.id.split('/');
    }
    this.orgId = 983293 ^ this.idlist[0];
    this.portalId = 983293 ^ this.idlist[1];
    this.getVersion();
  }
  handlePortalStyles(){
    this.updateThemeColor();
    this.welcomeLogo = '../../../../assets/logos/'+colorConfig[this.domain]['logoName']+'.png'
    this.leftBgColor = colorConfig[this.domain]['publicScreenBackgroud'];
    this.myLocationBg = colorConfig[this.domain]['myLocationbtnColor'];
  }
  updateThemeColor() {
    const metaTag = document.querySelector('meta[name="theme-color"]');
    if (metaTag) {
      metaTag.setAttribute('content', colorConfig[this.domain]['headerBackground']);
    } else {
      this.createThemeColorMetaTag();
    }
  }

  createThemeColorMetaTag() {
    const meta = document.createElement('meta');
    meta.setAttribute('name', 'theme-color');
    meta.setAttribute('content', colorConfig[this.domain]['headerBackground']);
    document.head.appendChild(meta);
  }
  async getVersion() {
    var url = 'version';
    await this.apiService.getVersion(url).subscribe((data: any) => {
      this.backendVersion = data;
      this.apiService.addLocalStorage('Backend', data['Title'])
      this.apiService.addLocalStorage('BackendVersion', data['Version'])
      this.getMapDetails();
    }, (error) => {
      console.log(error);
      this.getVersion
    })
  }
  ngAfterViewInit() {
    this.mapsAPILoader.load().then(() => {
      if (this.searchElementRef && this.searchElementRef.nativeElement) {
        const service = new google.maps.places.PlacesService(
          this.searchElementRef.nativeElement
        );
        console.log(service)
      }
    });
  }
  async getMapDetails() {
    var url = 'organization?orgId=' + this.orgId + '&isPublic=true';
    this.apiService.getPublicMapDetails(url).subscribe((data: any) => {
      console.log(data);
      this.lat = data['siteSetting']['CenterLatitude'];
      this.lng = data['siteSetting']['CenterLongitude'];
      this.publicLat = data['siteSetting']['CenterLatitude'];
      this.publicLng = data['siteSetting']['CenterLongitude'];
      this.zoom = data['siteSetting']['zoomLevel'];
      this.helpUrl = data['siteSetting']['MunicipalityURL'];
      this.getPublicMapSettings();
      
      this.showMap = true;
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getPublicMapSettings() {
    this.allTabs = [];
    this.streetPriorities = [];
    this.publicmapSettings = {};
    var url ='publicMapSetting?orgId=' + this.orgId + '&portalId=' + this.portalId + '&domain=' + this.domain;
    await this.apiService.getByPublicUrl(url).subscribe((data: any) => {
      console.log(data)
      this.publicmapSettings = data;
      this.domain = data['portalType'].toLowerCase();
      this.handlePortalStyles();
      this.publicAlert = this.deviceWidth > 640 ? data['alertMessage']:data['mobileAlertMessage']
      this.status = data['status'];
      this.allTabs.push('Status');
      this.publicMapType = data['publicMap'][0]?data['publicMap'][0].split('.').pop():'';
      if (data['priorityMap'] && data['priorityMap'] !== '') {
        this.priorityMapType = data['priorityMap'].split('.').pop();
        this.streetPriorities = data['streetPriorities'];
        this.allTabs.push('Priority');
      }
      if(this.welcomeMessage){
        this.showWelcomeMsg();
      }else {
        if(this.deviceWidth < 640){
          this.openDrawer();
        }
      }
      this.getRoutesData();
    }, (error) => {
      this.getRoutesData();
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getRoutesData() {
    var url = 'transportPublic?orgId='+this.orgId+'&portalId='+this.portalId
    // 'publicMapTruck?orgId='+this.orgId+'&portalId='+this.portalId+'&domain='+this.domain;
    // var url = 'transportPublic?trackRouteBus=true'
    await this.apiService.getByPublicUrl(url).subscribe((data: any) => {
      console.log(data)
      this.publicRouteList = this.allTrType.concat(data);
      this.getAllRouteStreets();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getAllRouteStreets() {
    var url = 'transportPublic?orgId='+this.orgId+'&portalId='+this.portalId+'&trackRouteBus=true'
    var payload = {
      "orgId":Number(this.orgId),
      "portalId":this.portalId,
      "transportRouteId":[this.selectedRouteId],
    	"includeBusDetail":true,
	    "transportBusId":[0] 
  }
    // 'publicMapTruck?orgId='+this.orgId+'&portalId='+this.portalId+'&domain='+this.domain;
    await this.apiService.postNewPublicdata(url,payload).subscribe((data: any) => {
      console.log(data)
      this.allRouteStreetsData = data;
      this.getAllBusPositions();
      this.intervalId = setInterval(() => {
        this.getAllBusPositions();
      }, 15000);
      this.allRouteStreetsData.map((route: any)=>{
        if(route.transportRouteS3Url !== ''){
          this.http.get(route.transportRouteS3Url).subscribe(data => {
            console.log(data)
            route.routeStreet = this.splitStreetsFromGeojson(data);
            // this.getStopsData(data, getRoute);
          })
        }
      })
      console.log(this.allRouteStreetsData)
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  // call this function every 15 secs
  async getAllBusPositions() {
    var url = 'transportPublic?orgId='+this.orgId+'&portalId='+this.portalId+'&getBusPosition=true'+'&transportRouteId='+this.selectedRouteId
    // 'publicMapTruck?orgId='+this.orgId+'&portalId='+this.portalId+'&domain='+this.domain;
    await this.apiService.getByPublicUrl(url).subscribe((data: any) => {
      console.log(data)
      this.allBusDetails = [];
      this.allBusDetails = Object.assign([],data);
      this.showMap = true;
      this.showPage = true;
      this.helper.getLatestUIVersion('ssmPublic?key=UiVersion');
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async selectRoute(getRoute: any){
    this.closeDrawer();
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.BusIntervalId) {
      clearInterval(this.BusIntervalId);
    }
    this.routeGjson = {};
    this.busStopLists=[];
    console.log(getRoute);
    this.selectedRoute = getRoute;
    this.selectedRouteId = getRoute.Id;
    var url = 'transportPublic?orgId='+this.orgId+'&portalId='+this.portalId+'&transportRouteId='+getRoute.Id+'&trackRouteBus=true'
    // 'transport?orgId='+this.orgId+'&portalId='+this.newRoute['portalId']+'&transportRouteId='+getRoute.Id+'&trackRouteBus=true'
    this.getAllRouteStreets();
    
  }
  
  splitStreetsFromGeojson(data){
    this.showRoute = false;
    var routeGjson = {
      type: "FeatureCollection",
      features: []
    }
    if(data['features'] && data['features'].length >0){
      
      data['features'].map((feature: any)=>{
        if(feature.geometry.type === "LineString"){
          routeGjson.features.push(feature)
        }
      })
      return routeGjson
      // if(routeGjson.features.length >0){
      //   this.routeGjson = routeGjson
      // } else {
      //   this.helper.openQuickSnackBar("Bus route not available.","Ok")
      // }
    }
    // this.showRoute = true;
  }
  ngOndestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.BusIntervalId) {
      clearInterval(this.BusIntervalId);
    }
    this.apiService.addLocalStorage('showHeader', true)
  }
  showWelcomeMsg() {
    this.welcomeMessage=false;
    var title = 'Welcome!';
    const modalData = {};
    modalData['publicData'] = {};
    modalData['publicData'] = this.publicmapSettings;
    modalData['welcomeLogo'] = this.welcomeLogo;
    const confirmDialog = this.dialog.open(EditComponent, {
      data: {
        title: title,
        message: 'Welcome to ' + this.domain,
        data: modalData,
        orgId: this.orgId,
        type: 'welcome'
      },
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        console.log(result);
      }
      if(this.deviceWidth < 640){
        this.openDrawer();
      }
      this.welcomeMessage = false;
    });
  }
  getClusterDetails(event) {
    console.log(event)
  }
  getIconData(getUrl: string) {
    var icon = {
      url: getUrl,
      scaledSize: {
        width: 25,
        height: 25
      }
    }
    return icon;
  }
  hideForm() {
    this.showView = !this.showView
    this.formClass = this.formClass === 'left' ? 'form-container-0' : 'left';
    this.mapClass = (this.formClass === 'left') ? 'map-container-70' : 'map-container-100';
  }
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.CurrentLat = position.coords.latitude;
        this.CurrentLong = position.coords.longitude;
        this.zoom = 13;
        this.showCurrentLocation = true;
      }, function (e) {
        //Your error handling here
        console.log(e)
      }, {
        enableHighAccuracy: true
      });
    }
  }

  showLocation(event) {
    console.log(event)
    if (event.key === "Enter") {
      this.geocodeService.geocodeAddress(this.address)
        .subscribe((location: any) => {
          console.log(location)
          if (location.lat !== 0 && location.lng !== 0) {
            this.lat = location.lat;
            this.lng = location.lng;
            this.zoom = 15;
            this.ref.detectChanges();
          } else {
            this.helper.openSnackBar("Your address '" + this.address + "' is invalid", "Ok")
          }
        }
        );
    }
  }
  openHelp(){
    var title = 'Street Priority';
    const modalData = {};
    modalData['publicData'] = {};
    modalData['publicData'] = this.publicmapSettings;
    modalData['welcomeLogo'] = this.welcomeLogo;
    const confirmDialog = this.dialog.open(EditComponent, {
      data: {
        title: title,
        message: this.publicmapSettings.streetPriorityMessage,
        // 'Priority levels for servicing of local roadways is displayed in key under priority tab:',
        data: modalData,
        orgId: this.orgId,
        OrgName: "snow",
        helpUrl : this.helpUrl,
        type: 'help'
      },
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        console.log(result);
      }
    });
  }
  closeAlert(){
    this.alertClass = "hideAlert";
    document.getElementsByClassName('MobileAlertClass')[0]['style'].display = 'none';
  }
  getSvg(getSvg){
    var svgData = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getSvg);
    return svgData;
  }
  showPublicMaps(){
    this.lat=this.publicLat;
    this.lng = this.publicLng
  }
  zoomChange(event: any){
    this.zoom=event;
    console.log(this.zoom)
  }
  routeLineStringStyle(feature: any) {
    // #b99885 brown
    const strokeColor = feature.getProperty('stroke');
    const strokeWeight = feature.getProperty('strokeWeight');
    const strokeOpacity = feature.getProperty('strokeOpacity');
    return {
      strokeColor: strokeColor?strokeColor:'#b99885',
      strokeWeight: strokeWeight?strokeWeight:6,
      strokeOpacity: strokeOpacity && strokeColor !== '#FFFFFF'?strokeOpacity:strokeOpacity && strokeColor === '#FFFFFF'?0:0.7
    };
  }
  lineStringStyle(feature: any): any {
    const strokeColor = feature.getProperty('stroke');
    const highway = feature.getProperty('highway');
    const strokeOpacity = feature.getProperty('strokeOpacity');
    const strokeWeight = feature.getProperty('strokeWeight');
    
    // #b99885 brown
    return {
      strokeColor: strokeColor?strokeColor:'#1913E6',
      // highway === 'secondary'?'#b99885':highway === 'residential'?'#f1772e':strokeColor, // Change stroke color to blue
      strokeWeight: 10,
      strokeOpacity: strokeOpacity?strokeOpacity:0.5
    };
  }
  dalaLayerClicked(event: MouseEvent){
    console.log(event)
    var clickedLatLng = {
      lat: event['latLng'].lat(),
      lng: event['latLng'].lng()
    };
    console.log(clickedLatLng.lat+','+clickedLatLng.lng)
  }
  getbusStopDetails(busStopDetails, routeIndex, StopIndex){
    console.log(busStopDetails)
    var url = 'transportPublic?findBusArrivalTime=true';
    
    var payload = {
      "orgId":Number(this.orgId),
      "portalId":this.portalId,
      "transportRouteId":busStopDetails.TransportRouteId,
      "stopId":busStopDetails.Id,
      "busId":[0]
    }
    this.selectedBusStopDetails = {};
    this.apiService.postNewPublicdata(url, payload).subscribe(
      (res: any) => {
        console.log(res);
        if(Object.keys(res).length !== 0){
          var cHours = new Date().getHours();
          var cMinutes = new Date().getMinutes();
          var cSeconds = new Date().getSeconds();
          console.log("Current time is "+new Date())
          console.log("Current time is "+cHours, cMinutes, cSeconds)
          for(let bus in res){
            res[bus]['arrivalTime'] = "";
            const newTime = new Date();
            newTime.setHours(newTime.getHours() + res[bus]['durationHr']);
            newTime.setMinutes(newTime.getMinutes() + res[bus]['durationMin']);
            newTime.setSeconds(newTime.getSeconds() + res[bus]['durationSec']);
            
            res[bus]['arrivalTime'] = this.datepipe.transform(newTime, 'yyyy-M-dd HH:mm:ss');
          }
          this.allRouteStreetsData[routeIndex]['stops'][StopIndex]['eta'] = Object.entries(res);
        }
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  expandView(){
    this.showContent = !this.showContent
  }
  checkFullScreen(){
    var deviceWidth = window.innerWidth
    return deviceWidth > 640?true: false;
  }
  closeDrawer() {
    this.drawer.close(); // Closing the drawer programmatically
  }
  openDrawer(){
    this.drawer.open();
  }
  getRouteName(routeId){
    var routeObj = this.publicRouteList.find(route => {return route.Id === routeId})
    return routeObj && routeObj.RouteName?routeObj.RouteName: 'N/A'
  }
  getbusDetails(busDetais: any, busI: number) {
    console.log(busDetais)
    var url = 'transportPublic?timeToReachNextStop=true';
    var payload = {
      "orgId": Number(this.orgId),
      "portalId": this.portalId,
      "transportRouteId": busDetais.transportRouteId,
      "busId": busDetais.TruckId
    }
    console.log(this.busReport)
    this.apiService.postNewPublicdata(url, payload).subscribe(
      (res: any) => {
        console.log(res);
        if (Object.keys(res).length !== 0) {
          var cHours = new Date().getHours();
          var cMinutes = new Date().getMinutes();
          var cSeconds = new Date().getSeconds();
          console.log("Current time is "+new Date())
          console.log("Current time is "+cHours, cMinutes, cSeconds)
          for(let bus in res){
            res[bus]['arrivalTime'] = "";
            const newTime = new Date();
            newTime.setHours(newTime.getHours() + res[bus]['durationHr']);
            newTime.setMinutes(newTime.getMinutes() + res[bus]['durationMin']);
            newTime.setSeconds(newTime.getSeconds() + res[bus]['durationSec']);
            
            res[bus]['arrivalTime'] = this.datepipe.transform(newTime, 'yyyy-M-dd HH:mm:ss');
          }
          console.log(this.allBusDetails)
          this.allBusDetails[busI]['nextEta']=[];
          this.allBusDetails[busI]['nextEta'] = Object.entries(res);
        }
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
}
